import React, {Suspense, lazy} from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
 
import './css/bootstrap.min.css';
import './css/style.css';
import './css/responsive.css';
import './css/owl.carousel.css';
import './css/jquery.dataTables.min.css';
import './css/responsive-data-table.css';
import './css/bootstrap-select.css';
import './css/notika-custom-icon.css';
  
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import config from './Data/config';
import PagePrivacy from './views/privacy';
import PageTerms from './views/terms';

const Home = lazy(()=>import('./views/Home'));
const GPIResultBoard =lazy(()=>import('./views/gpi-result-board'));
const GPIResultByState =lazy(()=>import('./views/gpi-state-score'));
const GPICompareState =lazy(()=>import('./views/gpi-compare-state'));
const GPIIndicatorStories =lazy(()=>import('./views/gpi-indicator-stories'));
const GPIDownload =lazy(()=>import('./views/gpi-download'));

const About =lazy(()=>import('./views/about'));
const Aboutwhere =lazy(()=>import('./views/about-where'));
const Aboutwe =lazy(()=>import('./views/about-who'));
const Aboutgpi =lazy(()=>import('./views/about-gpi'));
const Aboutsap =lazy(()=>import('./views/about-sap'));

const GetInvolved =lazy(()=>import('./views/page-get'));
const Resource =lazy(() =>import('./views/page-resource'));
const Contact =lazy(()=>import('./views/page-contact'));

const Errorpage =lazy(()=>import('./views/404'));
const Searchpage =lazy(()=>import('./views/search'));


class AppRoutes extends React.Component{
    async componentDidMount(){
        let gpi= JSON.parse(localStorage.getItem('gpi-main'));
        let time= parseInt(localStorage.getItem('downTime'));
        let expire=60*60*24*3*1000;
        let now= (new Date()).getTime();
        let diff=now-time;

        if(gpi){
            if(diff<expire){}
            else{ config.fetchGPIData(); }
        } else{ config.fetchGPIData(); }
            }
    render(){
        return(
             
            <Router>
                 <Suspense fallback={<div className="gpi-loading"><div className="green-pry-color">LOADING...</div></div>} >
                    <Switch>
                        <Route exact path="/"> <Home /> </Route>  

                        <Route path="/GPI/result-board">  <GPIResultBoard /> </Route>  
                        <Route path="/GPI/state-board">  <GPIResultByState /> </Route>  
                        <Route path="/GPI/compare-states">  <GPICompareState /> </Route>
                        <Route path="/GPI/indicator-stories">  <GPIIndicatorStories /> </Route>
                        <Route path="/download">  <GPIDownload /> </Route>

                        <Route path="/about-us/what-we-do">  <About /> </Route>
                        <Route path="/about-us/where-we-work">  <Aboutwhere /> </Route>
                        <Route path="/about-us/who-we-are">  <Aboutwe /> </Route>
                        <Route path="/about-us/gpi-and-sdgs">  <Aboutgpi /> </Route>
                        <Route path="/about-us/sponsors-and-partners">  <Aboutsap /> </Route>

                        <Route path="/get-involved">  <GetInvolved /> </Route>
                        <Route path="/resources">  <Resource /> </Route>
                        <Route path="/contact-us">  <Contact /> </Route>
                        <Route path="/search">  <Searchpage /> </Route>

                        <Route path="/terms">  <PageTerms /> </Route>
                        <Route path="/legal/terms-of-use/">  <PageTerms /> </Route>
                        <Route path="/privacy">  <PagePrivacy /> </Route>

                        <Route > <Errorpage /> </Route> 
                    </Switch>
                </Suspense>
            </Router>
             
             
        );
    }
}




ReactDOM.render(<AppRoutes />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
