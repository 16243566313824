 import React from 'react'
 import gif from '../img/loading19.gif';

 //remote https://api.governancemeter.org.ng/
 //local http://localhost:8000/
 //local wp http://localhost/wp/wp-json/wp/v2/posts/?_embed&per_page=4&_fields=title,link,content,_links
 //remote wp https://blog.governancemeter.org.ng/wp/wp-json/wp/v2/posts/?_embed&per_page=4&_fields=title,link,content,_links
 const config ={
   base:process.env.REACT_APP_BASE_URL,
   wp:"https:/insights.govmeter.ng/wp-json/wp/v2/posts/?_embed&per_page=4&_fields=title,link,content,_links",
   colorCodes:function(c){
                  if(c>0 && c<15){ return "#dc3545"; }
                  if(c>14 && c<30){ return "#e83e8c"; }
                  if(c>29 && c<45){ return "#98486d"; }
                  if(c>44 && c<60){ return "#d6b964"; }
                  if(c>59 && c<75){ return "#89e1ef"; }
                  if(c>74 && c<90){ return "#007bff"; }
   },
   Colors:{
    primary: "#1DB47F",
    HD: "#1DB47F",
    EO:"#028090",
    SRL:"#679436",
    second2: "#f18f01",
    second: "#FCC409",
    third: "#028090",
    fourth: "#679436",
    IHR:"#f18f01",
    black:"#272c2f"
   },
   findPos:function(pos){ let n; 
              switch(pos){
                case 1: n=pos+"st"; break;      case 2: n=pos+"nd"; break;      case 3: n=pos+"rd"; break;
                case 4: n=pos+"th"; break;      case 5: n=pos+"th"; break;      case 6: n=pos+"th"; break;
                case 7: n=pos+"th"; break;      case 8: n=pos+"th"; break;      case 9: n=pos+"th"; break;
                case 10: n=pos+"th"; break;     case 11: n=pos+"th"; break;     case 12: n=pos+"th"; break;
                case 13: n=pos+"th"; break;     case 14: n=pos+"th"; break;     case 15: n=pos+"th"; break;
                case 16: n=pos+"th"; break;     case 17: n=pos+"th"; break;     case 18: n=pos+"th"; break;
                case 19: n=pos+"th"; break;     case 20: n=pos+"th"; break;     case 21: n=pos+"st"; break;
                case 22: n=pos+"nd"; break;     case 23: n=pos+"rd"; break;     case 24: n=pos+"th"; break;
                case 25: n=pos+"th"; break;     case 26: n=pos+"th"; break;     case 27: n=pos+"th"; break;
                case 28: n=pos+"th"; break;     case 29: n=pos+"th"; break;     case 30: n=pos+"th"; break;
                case 31: n=pos+"st"; break;     case 32: n=pos+"nd"; break;     case 33: n=pos+"rd"; break;
                default: n=pos+"th";
              } return n;
        },
    fetchGPIData: async function(){
          //check last download time
          //console.log("I was called");
          let dt = localStorage.getItem("downTimeG"); //console.log("DT", dt)
          if (dt ==null || ( (new Date()).getTime()  - dt) > (1000*60*60*2) ){
                const gpi= await fetch(this.base+'gpi/get-main',{ method:'GET' 
                  }).then(response=>response.json());  
                
                  if(gpi && gpi.status && gpi.status=="ok"){
                    localStorage.setItem('gpi-main',JSON.stringify(gpi.data));
                    localStorage.setItem('downTimeG', (new Date()).getTime() );
                  }
          }
    },
    fetchGPINational: async function(){
        let dt = localStorage.getItem("downTimeN"); 
          if (dt ==null || ( (new Date()).getTime()  - dt) > (1000*60*60*2) ){
                const gpi= await fetch(this.base+'gpi/get-national',{ method:'GET' 
                  }).then(response=>response.json());  
                
                  if(gpi && gpi.status && gpi.status=="ok"){
                    localStorage.setItem('gpi-national',JSON.stringify(gpi.data));
                    localStorage.setItem('downTimeN', (new Date()).getTime() );
                  }
          }
},
    getStateCode:function(s){ let n;
                this.states.map((ss)=>{ 
                  if(ss.name.includes(s)){ n=ss.code; }
                }); return n;
    },
    getStateName:function(s){ let n;
                this.states.map((ss)=>{ 
                  if(ss.code==s){ n=ss.name; }
                }); return n;
    },
    getViewurl:function(s){
              let sc=s.toLowerCase().replaceAll(" ", "_")   //this.getStateCode(s);
              let url ="/GPI/state-board?state="+sc;
              return url;
    },
    validateState:function(s){ var r=false;
              this.states.map((ss)=>{
                if(ss.code==s){ r=true;}
              });   return r;
    },
    getIndicatorName:function(ii){
                let gp=JSON.parse(localStorage.getItem('gpi-main'))
                if(gp!==null && gp!==undefined){
                let inds= (gp).list;
                let n;
                for(var i=0; i<inds.length; i++){
                    if(inds[i].id==ii){n=inds[i].name;}
                }
                return n; } else{ window.setTimeout(this.getIndicatorName,3000)}
    },
    indicators:function(){
                let inds= (JSON.parse(localStorage.getItem('gpi-main')));
                if(inds!==null){ return inds.list;}
                else{  window.setTimeout(this.indicators, 3000);  }
    },
    getFamilyName:function(f){
                let n;
                this.family.map((fa)=>{
                  if(fa.id==f){n=fa.name; }
                })
                return n;
    },
    getScore:function(ss){ let gp=JSON.parse(localStorage.getItem('gpi-main'));
            if(gp){  
              //let gpi = (gp).gpi.sort((a,b)=>Number(a.score)-Number(b.score)); //ascending order
              let gpi = (gp).gpi.sort((a,b)=>Number(b.score)-Number(a.score)); //descending order
              let s= this.getStateCode(ss);
            let pp; let sc;
               for(var i=0; i<gpi.length; i++){
                 if(gpi[i].state==s){
                   pp=i; sc=gpi[i]; break;
                 }
               }
            let p=this.findPos(pp+1);
            let result ={
              rank:p,
              score:sc.score,
            }
            return result;
            }
    },
    getScoreCode:function(s){ let gp=JSON.parse(localStorage.getItem('gpi-main'));
            if(gp){  
              //let gpi = (gp).gpi.sort((a,b)=>Number(a.score)-Number(b.score)); //ascending order
              let gpi = (gp).gpi.sort((a,b)=>Number(b.score)-Number(a.score)); //descending order
            let pp; let sc;
               for(var i=0; i<gpi.length; i++){
                 if(gpi[i].state==s){
                   pp=i; sc=gpi[i]; break;
                 }
               }
            let p=this.findPos(pp+1);
            let result ={
              rank:p,
              score:sc.score,
            }
            return result;
            }
    },
    getTribeScore:function(ss){ let gp=JSON.parse(localStorage.getItem('gpi-main')); 
            if(gp){ 
              let s= this.getStateCode(ss); 
              let tribes= gp.tribe;
              let t=[];
              tribes.map((tb)=>{  
                  if(tb.state==s){   t= (tb.tribe);  }
              })
              return t;
            }
    },
    getTribeScore_:function(s){ let gp=JSON.parse(localStorage.getItem('gpi-main')); 
            if(gp){ 
              //let s= this.getStateCode(ss); 
              let tribes= gp.tribe;
              let t=[];
              tribes.map((tb)=>{  
                  if(tb.state==s){   t= (tb.tribe);  }
              })
              return t;
            }
    },
    collectionPos:function(col, par, val){  
                let pp;
                for(var i=0; i<col.length; i++){
                  if(col[i][par]==val){  
                    pp=i;   break;
                  }
                }
                let p=this.findPos(pp+1);
                return p
    },
    getScore_:function(ss, tr){ let gp=JSON.parse(localStorage.getItem('gpi-main')); 
            if(gp){  
              //step one, get tribe values.
              let trGPI; let trGPIs=[]; let trRank; 
              let tribes =gp.tribe;
                  tribes.map((tb)=>{
                    if(tb.state==ss){
                      tb.tribe.forEach((te)=>{  if(te.tribe==tr){trGPI=te.score; } })
                     }
                     tb.tribe.forEach((tee)=>{
                        if(tee.tribe==tr){trGPIs.push({state:tb.state,tribe:tee.tribe, score:tee.score})}
                     })
                  });
              trGPIs= trGPIs.sort((a,b)=>Number(b.score)-Number(a.score));
              trRank=this.collectionPos(trGPIs, "state",ss);
            // step two, get family values and rank.
            let fam; let thisFam=[]; let famm=[]; let famGPI=[]; let famCol=[];
            let family =gp.family;
                  family.map((fa)=>{
                    if(fa.state==ss){fam=fa.family;}
                  });
                  this.family.map((fa)=>{
                    if(fa.tribe==tr){thisFam.push(fa.id)}
                  });
                  thisFam.map((fa)=>{
                     for(var i=0; i<14; i++){
                       if(fam[i].family==fa){famm.push(fam[i])}
                     }
                  });
                  famm.map((fa)=>{
                        family.forEach((fb)=>{ 
                          for(var i=0; i<fb.family.length; i++){
                            if(fb.family[i].family==fa.family){famCol.push({state:fb.state, family:fa.family, score:fb.family[i].score});
                          }  
                          } 
                        });
                  });
                  famm.map((fa)=>{ let famColF=[];
                    for(var i=0; i<famCol.length; i++){
                        if(famCol[i].family==fa.family){
                          famColF.push({state:famCol[i].state, family:fa.family, score:famCol[i].score})
                        }
                    } 
                  famColF=famColF.sort((a,b)=>Number(b.score)-Number(a.score));  
                    let rk=this.collectionPos(famColF, "state",ss);
                    famGPI.push({family:fa.family, score:fa.score, rank:rk});
                  });
            //step three, get family collections, the values and rank.
              let indState; let indFam=[]; let indGPI=[];
              let inds=gp.list;
              let indL= gp.indicator;
                  indL.map((fa)=>{
                    if(fa.state==ss){indState=fa.indicator;}
                  });
                  thisFam.map((fa)=>{
                    inds.forEach((fb)=>{
                      if(fb.family==fa){  indFam.push({id:fb.id,name:fb.name, family:fa})  }
                    })
                  });
                  indFam.map((fa)=>{
                      for(var i=0; i<indState.length; i++){
                        if(indState[i].indicator==fa.id){  
                          let inlist=[];
                          indL.map((fb)=>{  
                            for(var ii=0; ii<fb.indicator.length; ii++){  
                               if(fb.indicator[ii].indicator==fa.id){  
                                 inlist.push({state:fb.state, indicator:fa.id, score:fb.indicator[ii].score})
                               }
                            }
                          });
                          inlist =inlist.sort((a,b)=>Number(b.score)-Number(a.score));
                          let ik=this.collectionPos(inlist, "state",ss);
                          indGPI.push({
                            id:fa.id, name:fa.name, rank:ik, score:indState[i].score, family:fa.family
                          })
                        }
                      }
                  });
              var result={
                tribe:{score:trGPI,rank:trRank},
                family:famGPI,
                indicator:indGPI
              }
              return result;
            }
    },
    getFamilyScore:function(s){ let gp=JSON.parse(localStorage.getItem('gpi-main')); 
            if(gp){  
              //let s= this.getStateCode(ss); 
              let family= gp.family;
              let f=[];
              family.map((tb)=>{  
                  if(tb.state==s){   f= (tb.family);  }
              })
              return f;
            }
    },
    getIndicatorScore:function(s){ let gp=JSON.parse(localStorage.getItem('gpi-main')); 
            if(gp){  
              let inds= gp.indicator;
              let f=[];
              inds.map((tb)=>{  
                  if(tb.state==s){   f= (tb.indicator);  }
              })
              return f;
            }
    },
    getHighestIndicators:function(ss){ let gp=JSON.parse(localStorage.getItem('gpi-main'));
            if(gp){  
              let lists;
              let re=[];
              let s= this.getStateCode(ss);
              for(var i=0; i<37; i++){ 
                if(gp.indicator[i].state==s){ lists=gp.indicator[i].indicator}
              }
              let list = lists.sort((a,b)=>Number(b.score)-Number(a.score));  
              for(var i=0; i<5; i++){
                  re.push({indicator:this.getIndicatorName(list[i].indicator), score:list[i].score})
              }
              return re; 
            }
      },
    getLowestIndicators:function(ss){ let gp=JSON.parse(localStorage.getItem('gpi-main'));
              if(gp){  
                let lists;
                let re=[];
                let s= this.getStateCode(ss);
                for(var i=0; i<37; i++){ 
                  if(gp.indicator[i].state==s){ lists=gp.indicator[i].indicator}
                }
                let list = lists.sort((a,b)=>Number(a.score)-Number(b.score));   
                for(var i=0; i<5; i++){
                    re.push({indicator:this.getIndicatorName(list[i].indicator), score:list[i].score})
                }
                return re; 
              }
      },
    getNationalAverageByFamily:function(){ let gp=JSON.parse(localStorage.getItem('gpi-main'));
              if(gp!==null && gp!==undefined){   
                let famList = gp.family; 
                let re=[]; 
                  this.family.forEach((fam)=>{
                    let totalsum=0;
                        famList.map((fl)=>{
                          fl.family.map((fs)=>{
                              if(fs.family==fam.id){
                                totalsum=totalsum+parseFloat(fs.score);
                              }
                          })
                        });
                    let average= (totalsum/37).toFixed(2);
                    re.push({id:fam.id, tribe:fam.tribe, name:fam.name, average:average});
                  });
                return re;
              }
              else{
                window.setTimeout(this.getNationalAverageByFamily, 3000);
              }
      },
    noticeBox:function(){
                let nb= document.createElement('div');
                nb.setAttribute('class','notice-box');
                return  nb;
              },

    gif:function(){
              let gf = document.createElement('img');
              gf.setAttribute('class','loading-gif');
              gf.setAttribute('src', gif);
              return gf;
            },
    dynamicSelect:function(data){
              let ds= document.createElement('select'); ds.setAttribute('class', 'selectpicker');
                      data.forEach((fam)=>{
                        let op=document.createElement('option');
                        op.setAttribute('value',fam.id); 
                        op.innerHTML=fam.name;
                        ds.appendChild(op)  
                  });
                  return ds;
    },
    fetchStory:async function(){
                      const stories= await fetch(config.base+'api/gpi/fetch-front-story',
                      { method:'POST', }).then(response=>response.json());
                      return stories;
    },
    fetchIndexedStory:function(){ var thisContext=this;
                    return new Promise(function(resolve, reject){
                    var openDB =indexedDB.open('GPIStories',1);
                    
                    openDB.onsuccess=function(){
                        var db= openDB.result;
                        var transaction=db.transaction('stories','readwrite');
                        var store = transaction.objectStore('stories');
                        var request= store.getAll(); //use get() for specific request
                        request.onsuccess=function(event){ 
                          if(event.target.result.length!=0) {resolve(event.target.result); }
                          else{ resolve('loading') // window.setTimeout(thisContext.fetchIndexedStory, 3000)
                          }
                        };
                        request.onerror=function(event){ reject(event)}
                     }
                    openDB.onupgradeneeded=async function(event){ 
                        var dbb =event.target.result;
                        var objStore= dbb.createObjectStore("stories", {autoIncrement:true});  
                            objStore.transaction.oncomplete=async function(event){
                                var storyF=await thisContext.fetchStory();
                                var storyObjStore= dbb.transaction("stories", "readwrite").objectStore("stories");
                                storyObjStore.add(storyF); 
                                //thisContext.setState({stories:storyF} );
                            }
                      }
                  });
    },

    tribe:[
      {code:'HD',     name:'Human Development'},
      {code:'EO',     name:'Economic Opportunity'},
      {code:'SRL',    name:'Safety and Rule of Law'},
      {code:'IHR',    name:'Inclusion and Human Rights'}
    ],
    family:[
      {id:1,    tribe:'HD',     name:'Welfare'},
      {id:2,    tribe:'HD',     name:'Education'},
      {id:3,    tribe:'HD',     name:'Health'},

      {id:4,    tribe:'EO',     name:'Public Management'},
      {id:5,    tribe:'EO',     name:'Business Environment'},
      {id:6,    tribe:'EO',     name:'Infrastructure'},
      {id:7,    tribe:'EO',     name:'Rural Environment'},

      {id:8,    tribe:'SRL',    name:'Rule of Law'},
      {id:9,    tribe:'SRL',    name:'Transparency and Accountability'},
      {id:10,   tribe:'SRL',    name:'Personal Safety'},
      {id:11,   tribe:'SRL',    name:'State Security'},

      {id:12,   tribe:'IHR',    name:'Participation'},
      {id:13,   tribe:'IHR',    name:'Rights'},
      {id:14,   tribe:'IHR',    name:'Gender'},
    ],
    states:[
      {code:'AB', name: "Abia State"},    {code:'AD', name:"Adamawa State"}, {code:'AK', name:"Akwa Ibom State"},
      {code:'AN', name:"Anambra State"},  {code:'BA', name:"Bauchi State"},  {code:'BAY', name:"Bayelsa State"}, 
      {code:'BE', name:"Benue State"},    {code:'BO', name:"Borno State"},   {code:'CR',  name:"Cross River State"}, 
      {code:'DE', name:"Delta State"},    {code:'EB', name:"Ebonyi State"},  {code:'EN',  name:"Enugu State"}, 
      {code:'ED', name:"Edo State"},      {code:'EK', name:"Ekiti State"},   {code:'GO',  name:"Gombe State"},  
      {code:'IM', name:"Imo State"},      {code:'JI', name:"Jigawa State"},  {code:'KD', name:"Kaduna State"}, {code:'KN', name:"Kano State"}, 
      {code:'KA', name:"Katsina State"},  {code:'KE', name:"Kebbi State"},   {code:'KO', name:"Kogi State"},  
      {code:'KW', name:"Kwara State"},    {code:'LA', name:"Lagos State"},   {code:'NA', name:"Nasarawa State"}, 
      {code:'NI', name:"Niger State"},    {code:'OG', name:"Ogun State"},    {code:'ON',  name:"Ondo State"}, 
      {code:'OS', name:"Osun State"},     {code:'OY', name:"Oyo State"},     {code:'PL',   name:"Plateau State"}, 
      {code:'RI', name:"Rivers State"},   {code:'SO', name:"Sokoto State"},  {code:'TA',   name:"Taraba State"}, 
      {code:'YO', name:"Yobe State"},     {code:'ZA', name:"Zamfara State"}, {code:'FCT', name:"Federal Capital Territory"}
      ],
 }

 export default config;