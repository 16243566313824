import React from 'react';
import Headerpage from '../components/header/headerPages';
import Footer from '../components/footer/footer';
 import Contact from '../components/pages/contact';
import TermsView from '../components/pages/termsp';
 
const headerinfo={
  title:'Terms of Use',
  desc:''
}

 class PageTerms extends React.Component{ 
   render(){  
     return(
      <React.Fragment>
        <Headerpage info={headerinfo} /> 
        <TermsView />        
        <Footer />
      </React.Fragment>
     )
   }
 }


export default PageTerms;
