import React from 'react';
import img from '../../img/loading19.gif'; 
import config from '../../Data/config';

class GetInvolved extends React.Component{
    constructor(props){
        super(props);
        this.state={
            fname:'',
            surname:'',
            email:'',
            phone:'',
            org:'',
            address:'',
            subject:'',
            message:'',
            error:false,
            errMessage:'',
            state:'',
        }
        this.ref=React.createRef();
    }

    submitContact=(e)=>{ e.persist();   
        this.setState({state:'busy'});
        var ld = document.createElement('img');  
                ld.setAttribute('src', img); 
                ld.setAttribute('class','loading-gif');
        e.target.innerHTML='';
        e.target.appendChild(ld);  
        if(this.state.fname.length<3){
            e.target.innerHTML='Send Message';
            this.setState({state:'', error:true, errMessage:'Incomplete Form: Provide a complete first name of minimum three characters'}); return;
        }
        if(this.state.surname.length<3){
            e.target.innerHTML='Send Message';
            this.setState({state:'', error:true, errMessage:'Incomplete Form: Provide a complete Last Name (surname) of minimum three characters'}); return;
        }
        if(this.state.email.length<5){
            e.target.innerHTML='Send Message';
            this.setState({state:'', error:true, errMessage:'Incomplete Form: Provide a  valid email address'}); return;
        }
        if(this.state.phone.length<7){
            e.target.innerHTML='Send Message';
            this.setState({state:'', error:true, errMessage:'Incomplete Form: Provide a valid phone number'}); return;
        }
        if(this.state.address.length<3){
            e.target.innerHTML='Send Message';
            this.setState({state:'', error:true, errMessage:'Incomplete Form: Provide a complete  contact address'}); return;
        }
        if(this.state.subject.length<3){
            e.target.innerHTML='Send Message';
            this.setState({state:'', error:true, errMessage:'Incomplete Form: Provide Subject for your message'}); return;
        }
        if(this.state.message.length<30){
            e.target.innerHTML='Send Message';
            this.setState({state:'', error:true, errMessage:'Incomplete Form: Provide a complete   message'}); return;
        }

        var data={
            firstName:this.state.fname,
            surname:this.state.surname,
            email:this.state.email,
            phone:this.state.phone,
            organization:this.state.org,
            address:this.state.address,
            subject:this.state.subject,
            message:this.state.message,
        }
            
        fetch(config.base+'api/pages/submit-contact',{
            method:'POST',
            body:JSON.stringify(data) }).then(response=>response.json()).then(resp=>{   console.log(resp)
              if(resp==='success'){
                  this.ref.current.reset();
                  e.target.innerHTML="Message Received:) Thanks!";
                  this.setState({error:false})
              }
              else{
                  this.setState( { state:'', errMessage:resp, error:true } );
                  e.target.innerHTML='Send Message';
              }
        });
      

    }
    render(){
        return(
            <div className="no-padding no-margin">
                <div className="row no-margin no-padding" style={{display:'flex', justifyContent:'center'}}>
                    <div className="col-lg-9 main-padding">  
                        <div className="db-h3">For Inquiries</div> <hr/>
                        <p style={{color:'#636b6f'}}>
                            <b>Email: </b> info@governancemeter.org.ng <br/>
                            <b>Phone: </b>(+234) 09017246800   <br/>
                            <b>Address: </b>No. 38, Orogun road, Ibadan, Nigeria.
                        </p>
                        <div className="db-h30 green-pry-color">Leave a message</div>
                         <hr />
                         <form ref={this.ref}>
                             <div className="row no-margin">
                                    <div className="col-sm-6">
                                        <input onChange={(e)=>this.setState({fname:e.target.value})} placeholder="First Name"  type="text" className="contact-input" />
                                    </div>
                                    <div className="col-sm-6">
                                        <input onChange={(e)=>this.setState({surname:e.target.value})} placeholder="Last Name"  type="text" className="contact-input" />
                                    </div>
                                    <div className="col-sm-6">
                                        <input onChange={(e)=>this.setState({email:e.target.value})} placeholder="Active Email"  type="email" className="contact-input" />
                                    </div>
                                    <div className="col-sm-6">
                                        <input onChange={(e)=>this.setState({phone:e.target.value})} placeholder="Phone Number"  type="number" className="contact-input" />
                                    </div>
                                    <div className="col-sm-6">
                                        <input onChange={(e)=>this.setState({org:e.target.value})} placeholder="Organization"  type="text" className="contact-input" />
                                    </div>
                                    <div className="col-sm-6">
                                        <input onChange={(e)=>this.setState({address:e.target.value})} placeholder="Address"   type="text" className="contact-input" />
                                    </div>

                                    <div className="col-sm-12">
                                        <input onChange={(e)=>this.setState({subject:e.target.value})} placeholder="Message Subject"   type="text" className="contact-input" />
                                    </div>

                                    <div className="col-sm-12">
                                        <textarea onChange={(e)=>this.setState({message:e.target.value})} placeholder="Message"   type="text" className="contact-textarea" ></textarea>
                                    </div>

                                    <div className="col-sm-12" style={{display: this.state.error==false? "none": "block"}} >
                                        <div className="errMessage"> {this.state.errMessage}</div>
                                    </div>
                                         
                                    <div style={{marginTop:'1.2em'}} className="col-sm-6">
                                        <div style={{minWidth:'60%'}}  className="subhead-tab align-center"
                                        onClick={this.state.state==''?this.submitContact:null} >Send Message </div>
                                    </div>
                                </div>
                         </form>
                    </div>
                </div>
            </div>
        )
        
    }
}
 

export default GetInvolved;