import React, {useEffect} from 'react';
import { Link} from 'react-router-dom';



function Desktopmenu (){
let path = window.location.pathname;
return(
            <div className="desktop-menu-tray">
                <Link to="/" className={path=="/" ? "desktop-menu-list-active":"desktop-menu-list"}>Home </Link>
                <Link to="/about-us/what-we-do" className={path.includes("about-us") ? "desktop-menu-list-active": "desktop-menu-list"}>About Us</Link>
                <Link to="/GPI/result-board" className={path.includes("GPI") ? "desktop-menu-list-active": "desktop-menu-list"}>View Index</Link>
                <Link to="/resources" className={path=="/resources" ? "desktop-menu-list-active":"desktop-menu-list"}>Resources</Link>
                <Link to="/get-involved" className={path=="/get-involved" ? "desktop-menu-list-active":"desktop-menu-list"}>Get Involved</Link>
                <Link to="/contact-us"  className={path=="/contact-us" ? "desktop-menu-list-active":"desktop-menu-list"}>Contact Us</Link>
            </div>
)
        
}
 

export default Desktopmenu;