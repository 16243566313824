import React from 'react';
import Headerpage from '../components/header/headerPages';
import Footer from '../components/footer/footer';
import PrivacyView from '../components/pages/privacyp';
 
const headerinfo={
  title:'Privacy Policy',
  desc:''
}

 class PagePrivacy extends React.Component{ 
   render(){  
     return(
      <React.Fragment>
        <Headerpage info={headerinfo} /> 
        <PrivacyView />        
        <Footer />
      </React.Fragment>
     )
   }
 }


export default PagePrivacy;
